import { useEffect, useState } from 'react';
  
export default function Example({...props}) {
    const [path, setPath] = useState();

    useEffect(() => {
        console.log(window.location.pathname);
        let path = window.location.pathname;
        setPath(path);
    }, []); 
    return (
      <div className="product-list-gradient">
        <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
          <div className="columns-2 w-full my-2 leading-tight text-white">
            <p className="text-left text-bold text-2xl">{props.category}</p>
            <p className="text-right text-bold pt-2">{props.sareesList.length} sarees available</p>
          </div>
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {props.sareesList.map((product) => (
                <div key={product.id} className="rounded-r-lg border-4 border-solid border-gold bg-white text-fontColor">
                  <div className=" w-full overflow-hidden bg-white">
                    <a href={`/details${path}/${product.id}`}>
                      <img className="h-full w-full object-cover" alt="" src={product.imageSrc}></img>
                    </a>
                  </div>
                  <div>
                  <p className="flex justify-center text-lg font-bold "><span className="text-base">M.R.P.:&nbsp;</span><span className="text-xl line-through">{product.mrp}</span></p>
                  <p className="flex justify-center text-lg font-bold "><span className="text-base">Offer price:&nbsp;</span><span className="text-xl">{product.price}</span></p>
               </div>
                  <div className="">
                    <div className="flex items-center justify-center">
                      <a href={`/details${path}/${product.id}`}>
                        <button className="animate-pulse outline outline-offset-2 outline-gold mx-auto lg:mx-0 hover:underline bg-fontColor text-white font-bold rounded-full my-2 py-2 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                          Click to details
                        </button>
                      </a>
                    </div>
                  </div>
                   </div>
                
            ))}
          </div>
        </div>
      </div>
    )
  }
  