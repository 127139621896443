import Image from '../main/Image'
import HorzontalProductList from './HorizontalProductList';
  
  export default function Example({...props}) {
    let wa = `https://wa.me/919940783938?text=Hi%20SSP%20Silk%2C%0AI%20am%20interested%20in%20this%20Saree%0A${window.location.href}`
    return (
      <div className="details-gradient">
        {
          props.selectedProduct && (
          <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8 text-white">
            <div className="w-full my-2 text-white">
              <p className="text-left text-bold text-2xl">{props.selectedProduct.name}</p>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                  <div className="bg-white border-4 border-solid border-gold text-fontColor">
                    <div className=" w-full overflow-hidden rounded-tr-lg bg-white">
                      <Image src={props.selectedProduct.imageSrc} border="true" zoom={true}></Image>
                    </div>
                    <p className="flex justify-center text-lg font-bold "><span className="text-base">M.R.P.:&nbsp;</span><span className="text-xl line-through">{props.selectedProduct.mrp}</span></p>
                    <p className="flex justify-center text-lg font-bold "><span className="text-base">Offer price:&nbsp;</span><span className="text-xl">{props.selectedProduct.price}</span></p>
                  </div>
                  <p className="mt-4 font-bold">Product Specifications</p>
            </div>
            <div className="mt-2 grid grid-cols-2 border-white-600 border-0 gap-0 lg:gap-1 lg:grid-cols-4 text-left text-lg text-white">
                <div className="p-1 border-white-600 border-2 font-bold">Category</div>
                <div className="p-1 border-white-600 border-2">{props.spec.category}</div>
                <div className="p-1 border-white-600 border-2 font-bold">Fabric</div>
                <div className="p-1 border-white-600 border-2">{props.spec.fabric}</div>
                <div className="p-1 border-white-600 border-2 font-bold">Origin</div>
                <div className="p-1 border-white-600 border-2">{props.spec.origin}</div>  
                <div className="p-1 border-white-600 border-2 font-bold">Blouse</div>
                <div className="p-1 border-white-600 border-2">{props.spec.blouse}</div>
                <div className="p-1 border-white-600 border-2 font-bold">Zari</div>
                <div className="p-1 border-white-600 border-2">{props.spec.zari}</div>
                <div className="p-1 border-white-600 border-2 font-bold">Saree Dimension</div>
                <div className="p-1 border-white-600 border-2">{props.spec.dimension}</div>
                <div className="p-1 border-white-600 border-2 font-bold">Washing</div>
                <div className="p-1 border-white-600 border-2">{props.spec.washing}</div>
            </div>
            <div>
              <p className="mt-4 font-bold">Next Steps:</p>
              <p> To buy this Saree  : <span className="underline"><a href={wa}>Click to WhatsApp<span className="text-2xl animate-ping">*</span></a></span></p>
            </div>  
            <div>
              <p className="mt-4 font-bold">Having queries?</p>
              <p> WhatsApp Us : <span className="underline"><a href="https://wa.me/919940783938">Click Here</a></span></p>
              <p> Call Us : <span className="underline"><a href="tel:9940783938">9940783938</a></span></p>
              <p> Mail Us : <span className="underline"><a href="mailto:cs@swarika.com">cs@swarika.com</a></span></p> 
            </div>
            <div>
              <p className="mt-4 font-bold underline">Similar sarees</p>
            </div>  
            <HorzontalProductList similarProducts={props.similarProducts} spec={props.spec} />
          </div>
        )}        
      </div>
    )
  }