import ProductList from "./ProductList";
import { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export default function Example() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);


    useEffect(() => {
        try {
          if (!executeRecaptcha) {
            console.error('ReCAPTCHA has not been loaded');
            return;
          }
          const executeForToken = async () => {
            const token = await executeRecaptcha('blogVideo'); 
            fetch('https://api.swarika.com/products'+window.location.pathname, {
              method: 'GET',
              headers: {
                'x-recaptcha-token': token,
              },
            }).then((response) => {
              if (response) {
                return response.json();
              }
              throw new Error('Something went wrong');
            })
            .then((responseJson) => {
              let products = responseJson?.products;
              console.log(products);
              setLoading(false);
              setProducts(products);
              localStorage.setItem('tempProducts', JSON.stringify(products[0]));
            })
            .catch((error) => {
              console.log(error)
              setLoading(false);
            });
    
          };
          executeForToken();
        } catch (error) {
          console.error('Error making API call:', error.message);
          setLoading(false);
        }
      }, [executeRecaptcha]);
    return (
        <div>
            {loading && (
                [...Array(5)].map((elementInArray, index) => ( 
                    <div key={index}>
                            <h3><Skeleton count={2} /></h3>
                            <Skeleton height={100} width={`95%`} />
                    </div>
                ))
            )}
            {products.map((product) => (
                <div key={product.category}>
                    <ProductList category={product.category} sareesList={product.products} />
                </div>    
            ))}
        </div>
    )
}        

  