import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import logo from '../../logo.png';

  export default function Testimonials() {
	
    const { executeRecaptcha } = useGoogleReCaptcha();
	const [tmons, setTmons] = useState([]);
	const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        try {
          if (!executeRecaptcha) {
            console.error('ReCAPTCHA has not been loaded');
            return;
          }
          const executeForToken = async () => {
            const token = await executeRecaptcha('blogVideo'); 
            fetch('https://api.swarika.com/tmons', {
              method: 'GET',
              headers: {
                'x-recaptcha-token': token,
              },
            }).then((response) => {
              if (response) {
                return response.json();
              }
              throw new Error('Something went wrong');
            })
            .then((responseJson) => {
              setTmons(responseJson?.tests);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error)
              setLoading(false);
            });
    
          };
          executeForToken();
        } catch (error) {
          console.error('Error making API call:', error.message);
          setLoading(false);
        }
      }, [executeRecaptcha]);

      
    return (
        <section className="bg-mainThreeColor leading-normal tracking-normal px-6 py-24 sm:py-32 lg:px-8">
          <div className='border-2 border-solid border-fontColor w-full p-6 flex flex-col flex-grow flex-shrink'>
          <h2 className="w-full my-2 text-3xl font-bold leading-tight text-center text-fontColor">
            Testimonials
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
        {loading && (
            <div>
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                <figure className="mt-10">
                    <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                    <p>
                        <Skeleton height={50} width={`75%`} />  
                    </p>
                    </blockquote>
                    <div className="mt-10">
                        <div className="font-semibold text-gray-900"><Skeleton count={1} /></div>
                    </div>
                </figure>
                </div>
            </div>            
        )}
        <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true}>
            {tmons.map((tmon) => (
                <div key={tmon.id}>
                    <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <figure className="mt-10">
                        <blockquote className="text-center text-xl font-semibold leading-8 text-fontColor sm:text-2xl sm:leading-9">
                        <p>
                        &quot;{tmon.feed}&quot;
                        </p>
                        </blockquote>
                        <figcaption className="mt-10">
                        <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                            <div className="font-semibold text-fontColor">{tmon.name}</div>
                            <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                            <circle cx={1} cy={1} r={1} />
                            </svg>
                            <div className="text-fontColor">{tmon.place}</div>
                        </div>
                        </figcaption>
                    </figure>
                    </div>
                </div>
            ))}
        </Carousel>
        </div>
      </section>
    
    )
}
