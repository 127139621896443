import logo from '../../logo.png';

export default function Footer() {
  return (
<footer className="gradient">
      <div className="container mx-auto px-8">
        <div className="w-full flex mb-4">
            <a className="mt-8 " href="/">
              <img
                className="border-4 border-gold h-16 w-auto"
                src={logo}
                alt="SSP Silk"
              />
            </a>
        </div>
        <div className="contactUs bg-white mb-6 grid rounded-tr-lg border-4 border-fontColor font-bold grid-cols-2 border-0 gap-0 lg:gap-1 lg:grid-cols-4 text-lg text-fontColor">
            <div className="p-1 text-right">Contact : </div>
            <div className="p-1">D. Mohan Sah</div>
            <div className="p-1 text-right">WhatsApp Us : </div>
            <div className="p-1 underline"><a href="https://wa.me/919940783938">Click Here</a></div>
            <div className="p-1 text-right">Call Us : </div>
            <div className="p-1 underline"><a href="tel:9940783938">9940783938</a></div>
            <div className="p-1 text-right">Mail Us : </div>
            <div className="p-1 underline"><a href="mailto:cs@swarika.com">cs@swarika.com</a></div>
        </div>
        <div className="w-full flex text-white font-bold items-center justify-center">
          © 2024 SSP Silk
        </div>
        <div className="relative -mt-12 lg:-mt-24">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </footer>
      )
    }