import Collections from './Collections';
import CashZari from './CashZari';
import Women from './W.jpg';
import Testimonials from './Testimonials';

export default function Landing() {
    return (
  <div className="leading-normal tracking-normal bg-mainTwoColor text-fontColor" >
    {/** <!--Hero--> **/}
    <div className="pt-24 lg:pt-5 lg:pb-5">
      <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        {/** <!--Left Col--> **/}
        <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <p className="uppercase tracking-loose text-3xl w-full font-bold">ssp silk</p>
          <h1 className="my-4 text-5xl font-bold leading-tight">
          </h1>
          <p className="leading-normal text-2xl mb-8">
            Unleash Your Elegance: Discover Timeless Saree Styles on Our Saree Blog!
          </p>
          <a href="https://blog.swarika.com" className="main-explore ani mx-auto lg:mx-0 hover:underline bg-fontColor text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            Swarika Saree Blog
          </a>
        </div>
        {/** <!--Right Col--> **/}
        
        <div className="w-full md:w-3/5 text-center [clip-path:polygon(0%_15%,15%_15%,15%_0%,85%_0%,85%_15%,100%_15%,100%_85%,85%_85%,85%_100%,15%_100%,15%_85%,0%_85%)]">
          <img className="w-full md:w-5/5 z-50" src={Women} alt="" />
        </div>
      </div>
      <div className="relative -mt-12 lg:-mt-24">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
    </div>
    <Testimonials />
    <Collections />
    <CashZari />
  </div>
      )
    }