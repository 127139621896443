import { useEffect, useState } from 'react';

export default function Example({...props}) {
    const [detailsPath, setDetailsPath] = useState();
    const [path, setPath] = useState();

    useEffect(() => {
        console.log(window.location.pathname);
        let pathWithId = window.location.pathname;
        let vals = pathWithId.split('/');
        let detailsPath = vals[1];
        setDetailsPath(detailsPath);
        let path = vals[2];
        setPath(path);
    }, []); 

    return (
      <div className="">
        <div className="flex flex-col m-auto p-auto">
            <div className="mt-5 flex overflow-x-scroll pb-12 hide-scroll-bar">
                <div className="animate-infinite-scroll flex gap-4 flex-nowrap ml-10">
                {props.similarProducts.map((product) => (
                    <div key={product.id}>
                        <div className="border-4 border-gold  w-full overflow-hidden rounded-tl-lg bg-white">
                            <a key={product.id} href={`/${detailsPath}/${path}/${product.id}`}>
                                <img className="h-full w-full object-cover object-center" alt="" src={product.imageSrc}></img>
                            </a>
                        </div>
                        <h3 className="mt-2 text-sm text-white">{product.name}</h3>
                        <p className="text-white">M.R.P:&nbsp;<span className="line-through">{product.mrp}</span><span className="text-2xl text-bold p-12 text-gold">&nbsp;{product.price}</span></p>
                    </div>
                ))}
                </div>
            </div>
        </div>
      </div>
    )
  }