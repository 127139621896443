import { useState } from 'react'
import styles from './image.module.css'

const Image = ({ zoom, ...rest }) => {
  const [click, setClick] = useState(false)

  const setFlag = () => {
    setClick(true)
  }

  const unsetFlag = () => {
    setClick(false)
  }

  if (!zoom) return <img alt="" {...rest} />
  else
    return (
      <>
        {click ? (
          <div onClick={unsetFlag} className={`cursor-zoom-out ${styles.lightbox} ${styles.show} relative`}>
            <img alt="" {...rest} className={`${styles.show_image}`}></img>
          </div>
        ) : (
          <div>
          <img alt="" className="cursor-zoom-in h-full w-full object-cover object-center bg-white" {...rest} onClick={setFlag}></img>
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <svg onClick={setFlag} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-8 h-8 stroke-white">
              <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
      </p>
          </div>
        )
        
        }
      </>
    )
}

export default Image
