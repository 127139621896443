import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSearchParams } from "react-router-dom";
import Swal from 'sweetalert2'


export default function Example() {
    const [searchParams, setSearchParams] = useSearchParams();
    const feedId = searchParams.get("feedId");
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [quality, setQuality] = useState();
    const [cost, setCost] = useState();
    const [service, setService] = useState();
    const [zari, setZari] = useState();
    const [feedback, setFeedback] = useState('');

    const [message, setMessage] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [messageData, setMessageData] = useState('');
    const [btnClass, setBtnClass] = useState('');

    const RadioInput = ({label, inputId, value, checked, setter}) => {
        return (
        <div className="flex items-center gap-x-3">
          <input
            id={inputId}
            type="radio"
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            checked={checked === value} 
            onChange={() => {
                setter(value)
            }}
          />
          <label htmlFor={inputId} className="block  font-medium leading-6 ">
            {label}
          </label>
        </div>
        );
    };
    

	useEffect(() => {
        
	  try {
		if (!executeRecaptcha) {
		  console.error('ReCAPTCHA has not been loaded');
		  return;
		}
		const executeForToken = async () => {
		  const token = await executeRecaptcha('blogVideo'); 
		  fetch('https://api.swarika.com/feed/'+feedId, {
        method: 'GET',
        headers: {
          'x-recaptcha-token': token,
        },
		  }).then((response) => {
        if (response) {
          return response.json();
        }
			  throw new Error('Something went wrong');
		  })
		  .then((responseJson) => {
              setData([{
                  name: responseJson?.data,
              }]);
              setLoading(false);
              setMessage(responseJson?.message);
              setMessageData(responseJson?.messageData);
		  })
		  .catch((error) => {
            setLoading(false);
            setMessage(true);
            setMessageData('Technical Error - Sorry your request cannot be processed');
		  });
  
		};
	  	executeForToken();
	  } catch (error) {
		  console.error('Error making API call:', error.message);
        setLoading(false);
        setMessage(true);
        setMessageData('Technical Error - Sorry your request cannot be processed');
	  }
      
      
	}, [executeRecaptcha]);

    const handleSubmit = e => {
      e.preventDefault();
      const data = {quality, cost, service, zari, feedId, feedback};
      if(data.quality == true || !data.quality) {
        setQuality(true);
        document.getElementById('qualityId').scrollIntoView({behavior: "smooth"})
        return;
      } else if(data.cost  == true || !data.cost ) {
        setCost(true);
        document.getElementById('costId').scrollIntoView({behavior: "smooth"})
        return;
      } else if(data.zari == true || !data.zari) {
        setZari(true);
        document.getElementById('zariId').scrollIntoView({behavior: "smooth"})
        return;
      } else if(data.service == true || !data.service) {
        setService(true);
        document.getElementById('serviceId').scrollIntoView({behavior: "smooth"})
        return;
      } 
      setDisabled(true);
      Swal.fire({
        title: "Submission in progress!",
        showConfirmButton: false,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        didOpen: async () => {
          Swal.showLoading();
          try {
            if (!executeRecaptcha) {
              console.error('ReCAPTCHA has not been loaded');
              return;
            }
            const token = await executeRecaptcha('blogVideo'); 
            fetch('https://api.swarika.com/feed', {
              method: 'POST',
              headers: {
                'x-recaptcha-token': token,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            }).then((response) => {
              if (response) {
                return response.json();
              }
              throw new Error('Something went wrong');
            }).then((responseJson) => {
              Swal.hideLoading();
              setLoading(false);
              setMessage(responseJson?.message);
              setMessageData(responseJson?.messageData);
              document.getElementById('topId').scrollIntoView({behavior: "smooth"});
              Swal.fire({
                title: "Thank you!",
                text: responseJson?.messageData,
                icon: "success"
              });
            }).catch((error) => {
              setMessage(true);
              setMessageData('Technical Error - Sorry your request cannot be processed');
              document.getElementById('topId').scrollIntoView({behavior: "smooth"});
              Swal.fire({
                title: "Oops!",
                text: 'Technical Error - Sorry your request cannot be processed',
                icon: "error"
              });
            })
          } catch (error) {
            console.error('Error making API call:', error.message);
            setMessage(true);
            setMessageData('Technical Error - Sorry your request cannot be processed');
            document.getElementById('topId').scrollIntoView({behavior: "smooth"});
            Swal.fire({
              title: "Oops!",
              text: 'Technical Error - Sorry your request cannot be processed',
              icon: "error"
            });
          }
        },
      });
	};
    
    const onChangeTextArea = e => {
      setFeedback(e.target.value);
    }
    const handleCancel = e => {
		e.preventDefault();
		    setCost(null);
        setQuality(null);
        setService(null);
        setZari(null);
        setFeedback('');
	};
  
  return (
    <div>
    <div id="topId" className="bg-white relative text-black font-semibold">
      <div className="p-4 text-lg text-justify mx-auto max-w-7xl lg:px-8">
        <h2 className="text-3xl text-center font-semibold leading-7 text-fontColor pb-3">SSP Silk</h2>
        {loading && (
             
             <div className="space-y-1">
        <div className="border-b border-gray-900">
          <h2 className="text-xl font-bold text-center font-semibold leading-7 "><Skeleton count={1} /></h2>
          <div className="mt-4  leading-6 text-black">
          <Skeleton count={1} />
          </div>
          <div> <b className='text-indigo-600'></b><Skeleton count={1} /></div>
          <div>&nbsp;</div>
          <div><Skeleton count={6} /></div>
          <div>&nbsp;</div>
          <div><Skeleton count={2} /></div>
          <div>&nbsp;</div>
          <div><Skeleton count={3} /></div>
          <div>&nbsp;</div>
          <div><Skeleton count={2} /></div>
          <div>&nbsp;</div>
          <div><Skeleton count={2} /></div>
        </div>
          {(
          [...Array(4)].map((elementInArray, index) => (
            <div key={`load-${index}`}className="border-b border-gray-900  pb-2">
              <div className="mt-5 space-y-1">
                <fieldset>
                  <legend id="qualityId" className="font-bold leading-6 text-indigo-600"><Skeleton height={10} width={`30%`} /></legend>
                  <div className="mt-1  leading-6 "><div><Skeleton count={2} /></div></div>
                  {(quality == true) && (
                    <div className="text-red-600">Please select your answer</div>
                  )}
                  <div className="mt-3 space-y-2">
                    <Skeleton count={3} />
                  </div>
                </fieldset>
              </div>
            </div>
          ))
          )}
        </div>
        )}

        {(message == false) && (
            <>
        {data.map((feed) => (
    <form key={feed.name}>
      <div className="space-y-1">
        <div className="border-b border-gray-900">
          <h2 className="text-xl font-bold text-center font-semibold leading-7 ">Customer Satisfaction Survey</h2>
          <div className="mt-4  leading-6 text-black">
          Warm regards, 
          </div>
          <div> <b className='text-fontColor'>{feed.name}</b>, our esteemed customer,</div>
          <div>&nbsp;</div>
          <div>At SSP Silk, we are committed to providing an exceptional shopping experience. Your satisfaction is our top priority, and we value your honest feedback to help us continuously improve our services.</div>
          <div>&nbsp;</div>
          <div>We kindly invite you to participate in this customer satisfaction survey. </div>
          <div>&nbsp;</div>
          <div>Your participation is voluntary, but we greatly appreciate your time and effort in helping us better serve you.</div>
          <div>&nbsp;</div>
          <div>Thank you in advance for your valuable contribution.</div>
          <div>&nbsp;</div>
          <div>Sincerely,</div> 
          <div>The SSP Silk Team</div>
        </div>

        <div className="border-b border-gray-900  pb-2">
          <div className="mt-5 space-y-1">
            <fieldset>
              <legend id="qualityId" className="font-bold leading-6 text-indigo-600">Quality</legend>
              <div className="mt-1  leading-6 ">How satisfied are you with the quality of the sarees you have purchased?</div>
              {(quality == true) && (
                <div className="text-red">Please select your answer</div>
              )}
              <div className="mt-3 space-y-2">
                <RadioInput label="Very Satisfied" inputId="qualityVerySatisfied" value="Very Satisfied" checked={quality} setter={setQuality} />
                <RadioInput label="Somewhat satisfied" inputId="qualitySomewhatSatisfied" value="Somewhat satisfied" checked={quality} setter={setQuality} />
                <RadioInput label="Not satisfied" inputId="qualityNotSatisfied" value="Not satisfied" checked={quality} setter={setQuality} />
              </div>
            </fieldset>
          </div>
        </div>

        <div className="border-b border-gray-900  pb-2">
          <div className="mt-5 space-y-1">
            <fieldset>
              <legend id="costId" className="font-bold leading-6 text-indigo-600">Cost</legend>
              <div className="mt-1  leading-6 ">How do you feel about the SSP Silk saree pricing?</div>
              {(cost == true) && (
                <div className="text-red">Please select your answer</div>
              )}
              <div className="mt-3 space-y-2">
                <RadioInput label="Low priced" inputId="costLowPriced" value="Low priced" checked={cost} setter={setCost} />
                <RadioInput label="Reasonably priced" inputId="costReasonablyPriced" value="Reasonably priced" checked={cost} setter={setCost} />
                <RadioInput label="Overpriced" inputId="costOverpriced" value="Overpriced" checked={cost} setter={setCost} />
              </div>
            </fieldset>
          </div>
        </div>
        <div className="border-b border-gray-900 pb-2">
          <div className="mt-5 space-y-1">
            <fieldset>
              <legend id="zariId" className="font-bold leading-6 text-indigo-600">Buying Old Zari sarees</legend>
              <div className="mt-1  leading-6 ">Do you know, at SSP Silk we buy Old Zari sarees for cash and we take care of shipping and return shipping. So you don't have to worry about courier charges.</div>
              {(zari == true) && (
                <div className="text-red">Please select your answer</div>
              )}
              <div className="mt-3 space-y-2">
                <RadioInput label="Yes, I know" inputId="zariYes" value="Yes" checked={zari} setter={setZari}  />
                <RadioInput label="I don't know" inputId="zariNo" value="No" checked={zari} setter={setZari}  />
              </div>
            </fieldset>
          </div>
        </div>
        <div className="border-b border-gray-900 pb-2">
          <div className="mt-5 space-y-1">
            <fieldset>
              <legend id="serviceId" className="font-bold leading-6 text-indigo-600">Customer Service</legend>
              <div className="mt-1  leading-6 ">How would you rate the overall level of customer service you received from us?</div>
              {(service == true) && (
                <div className="text-red">Please select your answer</div>
              )}
              <div className="mt-3 space-y-2">
                <RadioInput label="Very satisfied" inputId="serviceVerySatisfied" value="Very satisfied" checked={service} setter={setService}  />
                <RadioInput label="Somewhat satisfied" inputId="serviceSomewhatSatisfied" value="Somewhat satisfied" checked={service} setter={setService}  />
                <RadioInput label="Not satisfied" inputId="serviceNotSatisfied" value="Not satisfied" checked={service} setter={setService}  />
              </div>
            </fieldset>
          </div>
        </div>

        <div className="border-b border-gray-900 pb-2">
          <div className="mt-5 space-y-1">
            <fieldset>
              <legend id="serviceId" className="font-bold leading-6 text-indigo-600">Feedback</legend>
              <div className="mt-1  leading-6 ">Please share if any other feedback (optional).</div>
              <div className="mt-3 space-y-2">
                <textarea id="about" name="about" rows="3" maxLength={300}
                  onChange={onChangeTextArea}
                  className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      

      <div className="mt-3 flex items-center justify-center gap-x-6">
        <button type="button" onClick={handleCancel} className=" font-semibold leading-6 ">
          Cancel
        </button>
        <button
          type="submit"
          disabled={disabled}
          onClick={handleSubmit}
          className="rounded-md bg-fontColor text-white px-3 py-2  font-semibold  shadow-sm hover:bg-fontColor focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Save
        </button>
      </div>
    </form>
        ))}
        </>
        )}

        {message && (
             <div className='italic pt-12 pb-12 text-2xl text-center text-center'>
                 {messageData}
             </div>
        )}
      </div>
    </div>
    </div>  
  )
}
