import { createBrowserRouter } from "react-router-dom";
import Landing from "./components/main/Landing";
import Collections from "./components/main/Collections";
import SCollection from "./components/collection/SCollection";
import SCollectionDetails from "./components/details/SCollectionDetails";
import CashZari from "./components/main/CashZari";
import Feedback from "./components/main/Feedback";
import Testimonials from "./components/main/Testimonials";
import ShoppingCart from "./components/main/ShoppingCart";

const router = createBrowserRouter([
    { path: "/", element: <Landing /> },
    { path: "feedback", element: <Feedback /> },
    { path: "cart", element: <ShoppingCart /> },
    { path: "testimonials", element: <Testimonials /> },
    { path: "collections", element: <Collections /> },
    { path: "art", element: <SCollection /> },
    { path: "stone", element: <SCollection /> },
    { path: "soft", element: <SCollection /> },
    { path: "poch", element: <SCollection /> },
    { path: "pith", element: <SCollection /> },
    { path: "pure-e8k7adb", element: <SCollection /> },
    { path: "kpmcott", element: <SCollection /> },
    { path: "tuss", element: <SCollection /> },
    { path: "kanjicott", element: <SCollection /> },
    { path: "kpmcott0224", element: <SCollection /> },
    { path: "pith0224", element: <SCollection /> },
    
    { path: "details/art/:id", element: <SCollectionDetails />},
    { path: "details/stone/:id", element: <SCollectionDetails />},
    { path: "details/soft/:id", element: <SCollectionDetails />},
    { path: "details/poch/:id", element: <SCollectionDetails />},
    { path: "details/pith/:id", element: <SCollectionDetails />},
    { path: "details/pure-e8k7adb/:id", element: <SCollectionDetails />},
    { path: "details/kpmcott/:id", element: <SCollectionDetails />},
    { path: "details/tuss/:id", element: <SCollectionDetails />},
    { path: "details/kanjicott/:id", element: <SCollectionDetails />},
    { path: "details/kpmcott0224/:id", element: <SCollectionDetails />},
    { path: "details/pith0224/:id", element: <SCollectionDetails />},
    { path: "cashzari", element: <CashZari />},
  ]);

export default router;