
  export default function CashZari() {
    return (
        <section className="bg-mainTwoColor">
        <div className="leading-normal tracking-normal bg-mainTwoColor text-fontColor" >
            <div className="pt-8">
            <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
                <div className="container mx-auto flex flex-wrap pb-4">
          <h2 className="w-full my-2 text-3xl font-bold leading-tight text-center text-fontColor">
            Cash your old Zari saree
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="w-full font-bold text-xl text-center px-6">
                Get Cash for your unused Old Zari silk sarees
          </div>
          <div className="cashZari m-auto w-full m-8">
            <ol className="space-y-4 text-lg mr-4">
                <li>
                    <div className="justify-center w-full p-4 text-fontColor border-2 border-fontColor rounded-lg ">
                        <div className="flex items-center justify-between">
                            <h3 className="font-bold ">1. Contact Us</h3>
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="w-full p-4 text-fontColor border-2 border-fontColor rounded-lg">
                        <div className="flex items-center justify-between">
                            <h3 className="font-bold">2. Get estimation at your Doorstep</h3>
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor"strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="w-full p-4 text-fontColor border-2 border-fontColor rounded-lg">
                        <div className="flex items-center justify-between">
                            <h3 className="font-bold">3. Receive instant money</h3>
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor"strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </div>
                    </div>
                </li>
            </ol>
            </div>
            <div className="w-full underline decoration-double font-bold text-center px-6">
                In & around Chennai, We do in-person estimation at your Doorstep  
          </div>
          <a href="https://wa.me/919940783938?text=Hi%20SSP%20Silk%2C%0AI%20am%20interested%20in%20selling%20my%20Old%20Zari%20silk%20saree" className="animate-pulse w-1/2 lg:ml-20 text-center mx-auto lg:mx-0 hover:underline bg-fontColor text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            WhatsApp Us
          </a>
        </div>
                </div>
                <div className="rounded-t-lg border-2 border-fontColor w-full md:w-3/5 text-center">
                    <video autoPlay loop muted>
                        <source src="https://roopjith.swarika.com/home/zari-cash.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            </div>
            <div className="relative -mt-12 lg:-mt-24">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
        </div>
    </section>
    
    )
}
