import router from './router';
import Header from './components/main/Header';
import Footer from './components/main/Footer';
import {
  RouterProvider,
} from "react-router-dom";
import { useTour } from '@reactour/tour'
import { useEffect } from 'react';


function App() {
  const { setIsOpen } = useTour()

  useEffect(() => {
    if (!localStorage.getItem('tour') && window.location.pathname == "/") {
      setIsOpen(true);
      localStorage.setItem('tour', 'yes');  
    }  
  }, []);
   
  return (
    <div>
      <Header />
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;
