
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Swal from 'sweetalert2'

// Product Component
const Product = ({ product, addToCart }) => {
  return (
    <div id="topId" className="text-lg font-bold text-gray-900 border p-4 m-2 rounded shadow">
      <h2 className="">{product.name}</h2>
      <p className="text-gray-700">₹ {product.price}</p>
      <button
        className="bg-fontColor  text-white py-2 px-4 rounded mt-2 hover:bg-fontColor-600 "
        onClick={() => addToCart(product)}
      >
        Add to Cart
      </button>
    </div>
  );
};

// ShoppingCart Component
const ShoppingCart = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [cart, setCart] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [discount, setDiscount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [notes, setNotes] = useState('');

  const [message, setMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [messageData, setMessageData] = useState('');

  const products = [
    {
        id: 'BananaPith-1',
        name: 'Banana Pith Sarees',
        image: 'https://i.imgur.com/ohfEDZm.jpg',
        size: '13',
        price: 1500,
      },
      {
        id: 'ArtVi-2',
        name: 'Art Silk',
        image: 'https://i.imgur.com/74oR13w.jpg',
        size: 'XL',
        price: 795,
      },
      {
        id: 'SoftVi-3',
        name: 'Soft Silk (Art)',
        image: 'https://i.imgur.com/82cs9j1.jpg',
        size: 'XL',
        price: 2200,
      },
      {
        id: 'EveMonVi-4',
        name: 'Evening-Morning (Art)',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 2500,
      },
      {
        id: 'GiftKa-5',
        name: 'Gift Art Silk',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 1300,
      },
      {
        id: 'KpmCottVi-6',
        name: 'Kanchi Cotton - V',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 700,
      }, 
      {
        id: 'SemSilkPlain-7',
        name: 'Semi Silk Cotton (Plain)',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 1500,
      },     
      {
        id: 'SemSilkButta-8',
        name: 'Semi Silk Cotton (Butta)',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 1950,
      },     
      {
        id: 'SemSilkChecked-9',
        name: 'Semi Silk Cotton (Checked)',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 1550,
      },
      {
        id: 'SemSilkMultStripes-10',
        name: 'Semi Silk Cotton (multi-stripes)',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 1600,
      },     
      {
        id: 'KpmCott-11',
        name: 'Kanchi Cotton',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 800,
      },       
      {
        id: 'KpmCottZigZag-12',
        name: 'Kanchi Cotton (Zig-Zag)',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 750,
      },
      {
        id: 'KpmCottPoly-13',
        name: 'Kanchi Cotton (PolyCotton)',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 700,
      }, 
      {
        id: 'Dobby-14',
        name: 'Dobby Silk',
        image: 'https://i.imgur.com/dejlILO.jpg',
        size: 'XL',
        price: 550,
      }, 
  ];

  const addToCart = (product) => {
    setCart(prevCart => {
      const existingProduct = prevCart.find(item => item.product.id === product.id);
      if (existingProduct) {
        return prevCart.map(item =>
          item.product.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevCart, { product, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (product) => {
    setCart(prevCart => {
      const existingProduct = prevCart.find(item => item.product.id === product.id);
      if (existingProduct.quantity === 1) {
        return prevCart.filter(item => item.product.id !== product.id);
      } else {
        return prevCart.map(item =>
          item.product.id === product.id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      }
    });
  };

  const getTotalItems = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalCost = () => {
    const total = cart.reduce((total, item) => total + item.product.price * item.quantity, 0);
    return total - discount;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the collected data
    console.log('Name:', name);
    console.log('Phone:', phone);
    let totalItems = getTotalItems();
    let totalAmount = getTotalCost();
    console.log('Total Item:', totalItems);
    console.log('Total Sales:', totalAmount);
    console.log('Cart:', cart);
    console.log('Discount:', discount);
    console.log('Payment Method:', paymentMethod);
    console.log('Notes:', notes);

    const data = {name, phone, totalItems, totalAmount, cart, discount, paymentMethod, notes};
    setDisabled(true);
    Swal.fire({
      title: "Submission in progress!",
      showConfirmButton: false,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: async () => {
        Swal.showLoading();
        try {
          if (!executeRecaptcha) {
            console.error('ReCAPTCHA has not been loaded');
            return;
          }
          const token = await executeRecaptcha('blogVideo'); 
          fetch('https://api.swarika.com/stall', {
            method: 'POST',
            headers: {
              'x-recaptcha-token': token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          }).then((response) => {
            if (response) {
              return response.json();
            }
            throw new Error('Something went wrong');
          }).then((responseJson) => {
            Swal.hideLoading();
            setMessage(responseJson?.message);
            setMessageData(responseJson?.messageData);
            document.getElementById('topId').scrollIntoView({behavior: "smooth"});
            Swal.fire({
              title: "Thank you!",
              text: responseJson?.messageData,
              icon: "success"
            });
          }).catch((error) => {
            setMessage(true);
            setMessageData('Technical Error - Sorry your request cannot be processed');
            document.getElementById('topId').scrollIntoView({behavior: "smooth"});
            Swal.fire({
              title: "Oops!",
              text: 'Technical Error - Sorry your request cannot be processed',
              icon: "error"
            });
          })
        } catch (error) {
          console.error('Error making API call:', error.message);
          setMessage(true);
          setMessageData('Technical Error - Sorry your request cannot be processed');
          document.getElementById('topId').scrollIntoView({behavior: "smooth"});
          Swal.fire({
            title: "Oops!",
            text: 'Technical Error - Sorry your request cannot be processed',
            icon: "error"
          });
        }
      },
    });
  };

  return (
    <div className="font-bold bg-white relative text-gray-900 container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Shopping Cart</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {products.map(product => (
          <Product key={product.id} product={product} addToCart={addToCart} />
        ))}
      </div>
      <h2 className="text-2xl font-semibold mt-6">Cart</h2>
      <ul className="ml-6">
        {cart.map((item, index) => (
          <li key={index} className="text-xl my-2">
            {item.product.name} - ₹ {item.product.price} x {item.quantity}
            <button
              className="bg-fontColor text-white py-1 px-3 rounded ml-4 hover:bg-fontColor-600"
              onClick={() => removeFromCart(item.product)}
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
      <h2 className="text-2xl semibold mt-6">Summary</h2>
      <p className="text-xl" >Total Items: {getTotalItems()}</p>
      <p className="text-2xl text-fontColor" >Total Cost: ₹ {getTotalCost()}</p>
      <h2 className="text-2xl font-semibold mt-6">Customer Information</h2>
      <form onSubmit={handleSubmit} className=" mt-4 space-y-4">
        <div>
          <label className="block ">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="border border-gray-900 p-2 rounded w-full"
          />
        </div>
        <div>
          <label className="block ">Phone:</label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            className="border border-gray-900 p-2 rounded w-full"
          />
        </div>
        <div>
          <label className="block ">Discount:</label>
          <input
            type="number"
            value={discount}
            onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)}
            className="border border-gray-900 p-2 rounded w-full"
          />
        </div>
        <div>
          <label className="block ">Payment Method:</label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="border border-gray-900 p-2 rounded w-full"
          >
            <option value="cash">Cash</option>
            <option value="gpay">GPay</option>
          </select>
        </div>
        <div>
          <label className="block ">Notes:</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="border border-gray-900 p-2 rounded w-full"
          />
        </div>
        <button
          type="submit"
          disabled={disabled}
          className="bg-fontColor text-white py-2 px-4 rounded hover:bg-green-600"
        >
          Submit
        </button>
      </form>
      {message && (
       <div className='italic pt-12 pb-12 text-2xl text-center text-center'>
           {messageData}
       </div>
      )}
    </div>
  );
};

export default ShoppingCart;

