import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { TourProvider } from '@reactour/tour'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const steps = [
  {
    selector: '.site-tour',
    content: 'Welcome to Swarika.com. Lets take a quick site tour. [Please click Right arrow in this popup]',
  },
  {
    selector: '.contactUs',
    content: 'We are here to help you. [Please click Right arrow in this popup]',
  }, 
  {
    selector: '.cashZari',
    content: 'Get instant cash for Old Zari Sarees at your Doorstep. [Please click Right arrow in this popup]',
  },
  {
    selector: '.main-explore',
    content: 'Click to see all categories of Collections. Click X (at top-right in this popup) to close this popup.',
  } 
]

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey="6LcgcqgpAAAAAPstg5Nox3LtaUMY1IemJEw1qiDa">
      <TourProvider steps={steps}>
        <App />
      </TourProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


