export function getSimilarProducts(id, products) {
    let similarProducts = [];
    for(let i=0;i<products.length;i++) {
        if(products[i].id != id) { //Using !== breaks the flow
            similarProducts.push(products[i]);
        }
    }
    return similarProducts;
}

export function fetchSelectedProduct(id, products, maxId) {
    let selectedProduct = {};
    for(let i=0;i<maxId;i++) {
        if(products[i].id == id) { //Using !== breaks the flow
            selectedProduct = products[i];
        }
    }
    return selectedProduct;
}    