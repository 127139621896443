import { useEffect, useState } from 'react';
import ProductDetails from './ProductDetails';
import {getSimilarProducts} from '../main/Utils';


export default function Example() {
    const [similarProducts, setSimilarProducts] = useState([]);
    const [spec, setSpec] = useState({});
    const [selectedProduct, setSelectedProduct] = useState();

    useEffect(() => {
        let pathWithId = window.location.pathname;
        let vals = pathWithId.split('/');
        let id = vals[3];
        let cachedProducts = localStorage.getItem('tempProducts'); 
        let product = JSON.parse(cachedProducts);
        console.log(product);
        const selectedProduct = product.products[id-1];
        let similarProducts = getSimilarProducts(id, product.products);

        setSimilarProducts(similarProducts);
        setSpec(product.spec);
        setSelectedProduct(selectedProduct);
    }, []); 

  return (
    <div className="gradient">
      {
        selectedProduct && (
          <ProductDetails selectedProduct={selectedProduct} spec={spec} similarProducts={similarProducts} />  
        )}        
    </div>
  )
}