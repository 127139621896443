
const callouts = [
  {
    name: 'Kanchi Pure Cotton - Ganga Jamuna',
    description: 'Kanjivaram Pure Cotton. Traditional Ganga Jamuna Borders Body Meena Buttas, Lines Pallu with Running Blouse Sarees.',
    imageSrc: 'https://roopjith.swarika.com/kpm-cott-0224/1.png',
    href: 'kpmcott0224',
  },
  {
    name: 'Handwoven Banana Pith Sarees',
    description: 'Our banana pith sarees blend heritage and harmony. Sustainably made from banana plant fibers, these sarees make a vibrant and eco-friendly statement.',
    imageSrc: 'https://roopjith.swarika.com/pith-0224/4.png',
    href: 'pith0224',
  },
  {
    name: 'Kanchi Pure Cotton',
    description: 'Kanjivaram Pure Cotton. Traditional Jari Borders Body Meena Buttas, Lines Pallu with Running Blouse Sarees.',
    imageSrc: 'https://roopjith.swarika.com/kanji-0224/9.png',
    href: 'kanjicott',
  },
  {
    name: 'Tussar Raw Silk',
    description: 'Tussar Raw Silk Softy Antique Jari Fancy Border with Body Jari Buttas. Richpallu with Butta Blouse Sarees.',
    imageSrc: 'https://roopjith.swarika.com/tuss-0124/7.png',
    href: 'tuss',
  },
  {
    name: 'Kanchi Pure Cotton - Peacock Border',
    description: 'Kanchi Pure Cotton Jari Peacock & Temple border buttas with heavy pallu design sarees with blouse.',
    imageSrc: 'https://roopjith.swarika.com/kpmcot-0124/5.png',
    href: 'kpmcott',
  },
  {
      name: 'Pure Silk Sarees',
      description: 'Assortment of traditional pure silk sarees. Known for intricate weaving and vibrant colors.',
      imageSrc: 'https://roopjith.swarika.com/home/p3.png',
      href: 'pure-e8k7adb',
  },
  {
    name: 'Banana pith Sarees',
    description: 'Our banana pith sarees blend heritage and harmony. Sustainably made from banana plant fibers, these sarees make a vibrant and eco-friendly statement.',
    imageSrc: 'https://roopjith.swarika.com/home/2.png',
    href: 'pith',
  },
  {
    name: 'Silk cotton Pochampally',
    description: 'Drape yourself in regal elegance with our new silk cotton sarees featuring intricate Pochampally border designs. Let the luxurious textures and patterns speak for your style.',
    imageSrc: 'https://roopjith.swarika.com/home/3.png',
    href: 'poch',
  },
  {
    name: 'Semi soft silk',
    description: 'Our new semi soft silk sarees - Luxurious softness for your special occasions.',
    imageSrc: 'https://roopjith.swarika.com/home/4.png',
    href: 'soft',
  },
  {
    name: 'Art silk (stone work)',
    description: 'Sophisticated sparkle - Turn heads in our art silk sarees adorned with elegant stone embellishments.',
    imageSrc: 'https://roopjith.swarika.com/home/5.png',
    href: 'stone',
  },
  {
    name: 'Art silk',
    description: 'Art silk sarees - where comfort meets beauty. Crafted with fine art silk, these sarees are a delight to wear and behold.',
    imageSrc: 'https://roopjith.swarika.com/home/6.png',
    href: 'art',
  },    
  ]

  export default function Collections() {
    return (
    <section className="gradient border-b">
      <div className="container mx-auto flex flex-wrap pb-12">
        <h2 className="w-full my-2 text-3xl font-bold leading-tight text-center text-fontColor">
          Collections
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        {callouts.map((callout) => (
        <div key={callout.name} className="border-2 border-solid border-fontColor w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div className=" mx-auto flex-1 bg-white rounded-t-3xl overflow-hidden shadow-[5px_5px_0px_0px_rgba(189,24,119)]">
            <a href={callout.href} className={`${callout.href} flex flex-wrap items-center justify-center`}>
                <img
                  className="rounded-t-lg"
                  src={callout.imageSrc}
                  width={200} height={175}
                  alt="" />
              <div className="w-full font-bold text-xl text-center px-6">
                {callout.name}
              </div>
              <p className="text-fontColor text-justify text-base px-6 mb-5">
              {callout.description}
              </p>
            </a>
            </div>
            <div className="flex-none mt-auto bg-white overflow-hidden rounded-b-3xl shadow-[5px_5px_0px_0px_rgba(189,24,119)]">
              <div className="flex items-center justify-center">
                <a href={callout.href}>
                  <button className={`btn-${callout.href} ani outline outline-offset-2 outline-gold mx-auto lg:mx-0 hover:underline bg-fontColor text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform duration hover:scale-105 duration-300 ease-in-out`}>
                    Explore Collection
                  </button>
                </a>
              </div>
          </div>
        </div>
        ))}

      </div>
    </section>
    )
}